import { storeModuleName } from 'public/src/pages/product_app/store/modules/product_list_v2/index.js'
import { handleTrackFetchTime } from './handleTrack'

export default {
  async beforeCreate() {
    if (typeof window === 'undefined') return
    if (window._products_init_container) return // 已经初始化过了, 不进行预取html

    window._products_init_container = true // 标记已经初始化过了
    if (window._products_ssr_data) {
      this.$store.state[storeModuleName].context = window._products_ssr_data
    }
  },

  beforeMount() {
    if (typeof window === 'undefined') return
    if (window._products_prerender_el) {
      this.$el = window._products_prerender_el
      window._products_prerender_el = null
      if (window._products_ssr_data) {
        handleTrackFetchTime(window._products_ssr_data._traceId)
      }
    }
  },
  mounted() {
    if (window._gb_app_?.$store?.state?.rootStatus?.exchangingSpaScene) {
      window._gb_app_.$store.state.rootStatus.exchangingSpaScene = false // 显示页面的app
    }
  },
}
